<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorldOptions ref="helloOptions" msg="Hello Options World!" />
    <HelloWorldComposition
      ref="helloComposition"
      msg="Hello Composition World!"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";

import HelloWorldOptions from "@/components/HelloWorldOptions.vue";
import HelloWorldComposition from "@/components/HelloWorldComposition.vue";

import { typedRef } from "@/util/vue";

export default defineComponent({
  name: "Home",
  components: {
    HelloWorldComposition,
    HelloWorldOptions,
  },
  setup() {
    // Typed Template Refs Example using Composition API
    // docs: https://v3.vuejs.org/guide/composition-api-template-refs.html
    const refs = {
      helloOptions: ref<InstanceType<typeof HelloWorldOptions>>(), // no need to pass `null` here
      helloComposition: typedRef(HelloWorldComposition), // or use `typedRef` util (equivalent to above)
    };
    // alternately:
    //const helloOptions: typedRef(HelloWorldOptions), // no need to pass `null` here
    //const helloComposition: typedRef(HelloWorldComposition),

    // On render, call public methods of ref instances:
    onMounted(() => {
      refs.helloOptions.value?.sayHello();
      refs.helloComposition.value?.sayHello();
    });

    // Return refs so they will be properly registered (as outlined in docs above):
    return {
      ...refs,
    };
  },
});
</script>
