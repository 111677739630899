import type { Ref, UnwrapRef } from "vue";

import { ref, useCssModule } from "vue";

const exports = {
  // expose dependencies externally so they can be stubbed/mocked in tests
  // see: tests/util/vue.ts
  ref,
  useCssModule,
};

export function typedRef<T>(
  cls: new (...args: unknown[]) => T,
  value?: unknown
): Ref<UnwrapRef<T>> {
  return exports.ref<InstanceType<typeof cls>>(value as T);
}

export function typedTemplateRefs<T>() {
  return {
    refs: function (this: Record<string, unknown>): T {
      return this.$refs as T;
    },
  };
}

export function typedStyle<T>() {
  return {
    style: function () {
      return (exports.useCssModule() as unknown) as T;
    },
  };
}

Object.assign(exports, {
  typedRef,
  typedTemplateRefs,
  typedStyle,
});

export default exports;
