
import { defineComponent } from "vue";

import { typedStyle } from "@/util/vue";

export default defineComponent({
  name: "HelloWorldComposition",
  props: {
    msg: String,
  },
  setup() {
    // Public Methods:
    const sayHello = () => {
      console.info(
        "Hello world! (using composition API for component structure)"
      );
    };

    return {
      sayHello,
    };
  },
  render() {
    // TS+Vetur error IS shown on non-existent CSS class!
    // TS+Vetur error IS shown on non-existent component prop!
    //  <div class={style.helloIDoNotExist}>
    //    <h1>{ this.msgIDoNotExist }</h1>
    //  </div>
    this.sayHello();
    return (
      <div class={this.style.hello} onClick={this.sayHello}>
        <h1>{this.msg}</h1>
      </div>
    );
  },
  computed: {
    ...typedStyle<{
      hello: string;
    }>(),
  },
});
