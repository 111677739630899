
import { defineComponent } from "vue";

import { typedStyle } from "@/util/vue";

export default defineComponent({
  name: "HelloWorldOptions",
  props: {
    msg: String,
  },
  methods: {
    // Public Methods:
    sayHello(): void {
      console.info("Hello world! (using options API for component structure)");
    },
  },
  render() {
    // TS+Vetur error IS shown on non-existent CSS class!
    // TS+Vetur error IS shown on non-existent component prop!
    //  <div class={style.helloIDoNotExist}>
    //    <h1>{ this.msgIDoNotExist }</h1>
    //  </div>
    return (
      <div class={this.style.hello} onClick={this.sayHello}>
        <h1>{this.msg}</h1>
      </div>
    );
  },
  computed: {
    ...typedStyle<{
      hello: string;
    }>(),
  },
});
